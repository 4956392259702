import React from "react";
import theme from "theme";
import { Theme, Em, Text, Box, Section, Image, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Trim Line Барбершоп
			</title>
			<meta name={"description"} content={"Створена з пристрасті до позачасового стилю і мистецтва догляду, The Trim Line була створена з метою відродити класичну атмосферу перукарні, включивши до неї сучасні штрихи, що відповідають потребам сучасного чоловіка."} />
			<meta property={"og:title"} content={"Trim Line Барбершоп"} />
			<meta property={"og:description"} content={"Створена з пристрасті до позачасового стилю і мистецтва догляду, The Trim Line була створена з метою відродити класичну атмосферу перукарні, включивши до неї сучасні штрихи, що відповідають потребам сучасного чоловіка."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline3"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Ваше місце сучасної стрижки та бездоганного догляду
					</Em>
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--headline4"
				>
					<Em font-weight="normal">
						Зробіть крок у світ, де традиційні методи перукарського мистецтва поєднуються із сучасним стилем і комфортом. Незалежно від того, чи хочете ви швидко підстригтися, чи хочете повністю перетворитися, ми обіцяємо, що ви отримаєте одночасно розкішний і розслаблювальний досвід, завдяки якому матимете найкращий вигляд і почуватиметеся якнайкраще.
					</Em>
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" sm-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
				sm-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					min-width="600px"
					src="https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-4.jpg?v=2024-05-07T09:55:58.119Z"
					max-height="600px"
					object-fit="cover"
					lg-max-height="350px"
					lg-max-width="100%"
					width="100%"
					lg-width="100%"
					lg-min-width="350px"
					srcSet="https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-4.jpg?v=2024-05-07T09%3A55%3A58.119Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-4.jpg?v=2024-05-07T09%3A55%3A58.119Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-4.jpg?v=2024-05-07T09%3A55%3A58.119Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-4.jpg?v=2024-05-07T09%3A55%3A58.119Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-4.jpg?v=2024-05-07T09%3A55%3A58.119Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-4.jpg?v=2024-05-07T09%3A55%3A58.119Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-4.jpg?v=2024-05-07T09%3A55%3A58.119Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				background="--color-green"
				display="flex"
				flex-direction="column"
				lg-padding="24px 24px 24px 24px"
				empty-border-color="LightGray"
				align-items="center"
				justify-content="center"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="86px 86px 86px 86px"
				sm-width="100%"
			>
				<Text
					margin="0px 0px 32px 0px"
					color="--dark"
					font="--headline2"
					lg-font="700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					lg-margin="0px 0px 16px 0px"
				>
					Про нас
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
				>
					У The Trim Line ми пишаємося своєю увагою до деталей і прагненням до досконалості. Наші перукарі - досвідчені майстри, закохані у свою справу. Вони завжди в курсі останніх тенденцій і технологій, пропонуючи вам широкий вибір стилів: від класичних кроїв до сучасних фейдів. Тут йдеться не тільки про стрижку - йдеться про поліпшення вашого особистого стилю в затишній обстановці, створеній для вашого комфорту.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Чому варто обрати Trim Line Барбершоп?
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Ми цінуємо ваш час. Наші послуги виконуються швидко, без шкоди для якості, що гарантує, що ви повернетеся до свого дня стильно.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-2.jpg?v=2024-05-07T09:55:58.149Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-2.jpg?v=2024-05-07T09%3A55%3A58.149Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-2.jpg?v=2024-05-07T09%3A55%3A58.149Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-2.jpg?v=2024-05-07T09%3A55%3A58.149Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-2.jpg?v=2024-05-07T09%3A55%3A58.149Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-2.jpg?v=2024-05-07T09%3A55%3A58.149Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-2.jpg?v=2024-05-07T09%3A55%3A58.149Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-2.jpg?v=2024-05-07T09%3A55%3A58.149Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="24px 0 60px 0"
			md-padding="30px 0 30px 0"
			align-items="center"
			align-content="center"
			justify-content="center"
		>
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="29%"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				md-margin="0px 0px 30px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				md-display="flex"
			>
				<Image
					width="450px"
					height="450px"
					src="https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-3.jpg?v=2024-05-07T09:55:58.145Z"
					lg-width="320px"
					lg-height="320px"
					mix-blend-mode="normal"
					srcSet="https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-3.jpg?v=2024-05-07T09%3A55%3A58.145Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-3.jpg?v=2024-05-07T09%3A55%3A58.145Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-3.jpg?v=2024-05-07T09%3A55%3A58.145Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-3.jpg?v=2024-05-07T09%3A55%3A58.145Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-3.jpg?v=2024-05-07T09%3A55%3A58.145Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-3.jpg?v=2024-05-07T09%3A55%3A58.145Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/2-3.jpg?v=2024-05-07T09%3A55%3A58.145Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				flex-direction="column"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="70%"
				display="flex"
				justify-content="center"
				md-width="100%"
				mix-blend-mode="lighten"
			>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="40%">
						<Text font="--lead" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px" color="--green">
							Експертна майстерність
						</Text>
					</Box>
					<Box display="flex" align-items="center" width="60%">
						<Text margin="0px 0px 0px 0px" color="--light" font="--base" lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif">
							Кожен перукар The Trim Line є сертифікованим експертом як у традиційних, так і в сучасних перукарських техніках.
						</Text>
					</Box>
				</Box>
				<Box align-items="center" margin="0px 0px 16px 0px" display="flex">
					<Box display="flex" align-items="center" width="40%">
						<Text color="--green" font="--lead" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px">
							Індивідуальний підхід
						</Text>
					</Box>
					<Box display="flex" align-items="center" width="60%">
						<Text margin="0px 0px 0px 0px" color="--light" font="--base" lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif">
							Ми прислухаємося до ваших потреб і даємо індивідуальні поради та моделі, що відповідають вашому особистому стилю й уподобанням.
						</Text>
					</Box>
				</Box>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="40%">
						<Text margin="0px 0px 0px 0px" color="--green" font="--lead" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif">
							Якісна продукція
						</Text>
					</Box>
					<Box width="60%" display="flex" align-items="center">
						<Text font="--base" lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px" color="--light">
							Ми використовуємо тільки високоякісні засоби для догляду, які надають вашому волоссю і шкірі відчуття свіжості та пожвавлення.
						</Text>
					</Box>
				</Box>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="40%">
						<Text lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px" color="--green" font="--lead">
							Розслаблююча атмосфера
						</Text>
					</Box>
					<Box align-items="center" width="60%" display="flex">
						<Text margin="0px 0px 0px 0px" color="--light" font="--base" lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif">
							Насолоджуйтеся невимушеною атмосферою і безкоштовними напоями, щоразу гарантуючи розслаблюючий візит.
						</Text>
					</Box>
				</Box>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="60%" />
				</Box>
				<Box display="flex" align-items="flex-start" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="60%" />
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0">
			<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Створіть свій стиль
			</Text>
			<Box
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="80%"
			>
				<Text
					color="--light"
					font="--lead"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					text-align="left"
				>
					Створена з пристрасті до позачасового стилю і мистецтва догляду, The Trim Line була створена з метою відродити класичну атмосферу перукарні, включивши до неї сучасні штрихи, що відповідають потребам сучасного чоловіка.
					<br />
					<br />
					{"\n\n"}В основі The Trim Line - наші досвідчені перукарі, кожен з яких навчений як стародавньому перукарському мистецтву, так і новітнім тенденціям і методам.
					<br />
					<br />
					{" "}Наша команда не просто вміє поводитися з ножицями і бритвами, вони є творцями стилю і комфорту, гарантуючи, що кожна стрижка і гоління виконуються з точністю. Вони до ваших послуг, готові перетворити ваш образ і підвищити вашу впевненість у собі.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					object-fit="cover"
					lg-max-height="230px"
					src="https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-4.jpg?v=2024-05-07T09:55:58.150Z"
					max-height="400px"
					width="100%"
					object-position="bottom"
					srcSet="https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-4.jpg?v=2024-05-07T09%3A55%3A58.150Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-4.jpg?v=2024-05-07T09%3A55%3A58.150Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-4.jpg?v=2024-05-07T09%3A55%3A58.150Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-4.jpg?v=2024-05-07T09%3A55%3A58.150Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-4.jpg?v=2024-05-07T09%3A55%3A58.150Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-4.jpg?v=2024-05-07T09%3A55%3A58.150Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-4.jpg?v=2024-05-07T09%3A55%3A58.150Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Індивідуальні послуги
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Кожна послуга адаптована до ваших конкретних бажань і потреб, завжди забезпечуючи задоволення.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Image
					src="https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-2.jpg?v=2024-05-07T09:55:58.146Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-2.jpg?v=2024-05-07T09%3A55%3A58.146Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-2.jpg?v=2024-05-07T09%3A55%3A58.146Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-2.jpg?v=2024-05-07T09%3A55%3A58.146Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-2.jpg?v=2024-05-07T09%3A55%3A58.146Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-2.jpg?v=2024-05-07T09%3A55%3A58.146Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-2.jpg?v=2024-05-07T09%3A55%3A58.146Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d78e8d4a0c00202f1164/images/4-2.jpg?v=2024-05-07T09%3A55%3A58.146Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Увага до деталей
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Ми віримо, що диявол криється в деталях. Ось чому наші перукарі приділяють увагу кожному аспекту вашого візиту.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" margin="0px auto 0px auto" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="30%"
				padding="0px 40px 0px 15px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 60px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="#ffffff"
					font="--headline2"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-transform="uppercase"
				>
					ПОСЛУГИ
				</Text>
				<Text margin="0px 0px 1rem 0px" color="--lightD2" font="--base">
					Наші комплексні послуги з догляду створені для того, щоб кожен клієнт пішов не тільки в найкращому вигляді, а й почувався незвичайно!
				</Text>
				<Text margin="auto 0px 0px 0px" color="--lightD2" font="--base">
					Хочете дізнатися більше про наші послуги або вам потрібна порада про те, що найкраще підійде вашому стилю? Зв'яжіться з нашим досвідченим персоналом або відвідайте Trim Line сьогодні!
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="68%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Преміум-послуги
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Індивідуальні стрижки: від гострих, професійних до більш невимушених стилів - наші стрижки підбирають відповідно до вашого індивідуального стилю та вподобань.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Сеанси стайлінгу: створіть бажаний образ за допомогою наших професійних порад і прийомів стайлінгу.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Текстурування і нашарування: ідеально підходить для надання об'єму і динамізму вашому волоссю.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Розкішне гоління
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Класичне гоління. Насолоджуйтеся ритуалом класичного гоління за допомогою кремів для гоління преміум-класу та заспокійливих засобів після гоління.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Детальна стрижка бороди. Надайте бороді форму або підтримуйте її за допомогою точної стрижки й укладання.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Поліпшення догляду за шкірою. Включає відлущувальні та зволожувальні процедури для омолодження шкіри.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Комплексний догляд за бородою
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Формування та догляд за бородою: Індивідуальна стрижка для підтримання ідеальної форми та здоров'я бороди.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Кондиціонуючі процедури. Зволожуйте і живіть бороду за допомогою наших спеціально розроблених олій і бальзамів для бороди.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Консультації: Персональні поради щодо росту та догляду за бородою.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Рішення для догляду за волоссям
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Процедури для шкіри голови: Пожвавите шкіру голови за допомогою процедур, призначених для її живлення й омолодження.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Послуги з відновлення волосся: методи і процедури для боротьби з витонченням волосся.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Послуги з фарбування: Наші фахівці з фарбування можуть створити для вас ідеальний відтінок - від тонких змін до сміливих трансформацій.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});